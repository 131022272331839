import React, { useEffect, useState } from 'react';
import { getAspspList } from '../clients/bankingClient';
import { useNavigate } from 'react-router-dom';
import transition from '../transition';
import { handleAccountSelection, handleBankSelection } from '../Components/Banking/BankSelectionHandler';
import ListBanksComponent from '../Components/Banking/ListBanksComponent';
import ConsentSignComponent from '../Components/Banking/ConsentSignComponent';
import AccountSelectionComponent from '../Components/Banking/AccountSelectionComponent';
import PageSpinnerComponent from '../Components/Banking/PageSpinnerComponent';
import ErrorComponent from '../Components/Banking/ErrorComponent.jsx'
import { useKycPlusValidator } from '../KycPlusValidator';
import { useFormData } from '../FormDataContext.js'

const MaxRetryCount = 3;

const BankSelectionStages = {
  SELECT_BANK: 'SELECT_BANK',
  SIGN_CONSENT: 'SIGN_CONSENT',
  LOADING: 'LOADING',
  ACCOUNT_SELECTION: 'ACCOUNT_SELECTION',
  COMPLETE: 'COMPLETE',
  ERROR: 'ERROR'
};

function SelectBankPage() {
  const [aspspList, setAspspList] = useState([]);
  const [currentStage, setCurrentStage] = useState(BankSelectionStages.SELECT_BANK);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [selectedBankBicFi, setSelectedBankBicFi] = useState(null);
  const [scaData, setScaData] = useState({ token: '', image: '' });
  const { instanceData } = useFormData()
  const [consentCreated, setConsentCreated] = useState(false);
  const [currentRetryAction, setCurrentRetryAction] = useState();
  const [retryCount, setRetryCount] = useState(0);
  useKycPlusValidator(instanceData)

  const fetchAspspList = async () => {
    try {
      setCurrentRetryAction(() => handleRetryDefault);
      const result = await getAspspList();
      if (result.status === 200) {
        setAspspList(result.data.aspsps);
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred while retrieving the bank list. Please try again.');
      setCurrentStage('ERROR');
    }
  };

  useEffect(() => {
    fetchAspspList();
  }, []);

  useEffect(() => {
    if (currentStage === BankSelectionStages.COMPLETE) {
      navigate('/welcome');
    }
  }, [currentStage, navigate]);

  useEffect(() => {
    if (retryCount > MaxRetryCount) {
      navigate('/error', { state: { errorMessage: errorMessage } });
    }
  }, [retryCount, navigate, errorMessage]);

  const handleBankSelect = async (bicFi) => {
    setCurrentStage('LOADING');
    await handleBankSelection({
      bicFi,
      setCurrentStage,
      setAccounts,
      setSelectedBankBicFi,
      setScaData,
      consentCreated,
      setConsentCreated,
      instanceData,
      setCurrentRetryAction,
      setErrorMessage,
      setRetryCount
    });
  };

  const handleAccountSelect = async (account) => {
    await handleAccountSelection({
      account,
      setCurrentStage,
      setErrorMessage
    });
  };

  const handleRetryDefault = () => {
    setCurrentStage(BankSelectionStages.SELECT_BANK);
    fetchAspspList();
  };

  const onRetryCount = () => {
    setRetryCount(currentRetryCount => currentRetryCount + 1);
  };


  return (
    <div>
      {/* Render content based on the current stage */}
      {
        currentStage === BankSelectionStages.SELECT_BANK && 
        <ListBanksComponent 
          banks={aspspList} 
          onBankSelect={handleBankSelect} 
        />
      }
      {
        currentStage === BankSelectionStages.SIGN_CONSENT && 
        <ConsentSignComponent 
          scaData={scaData} 
        />
      }
      {
        currentStage === BankSelectionStages.LOADING && 
        <PageSpinnerComponent 
          error={error} 
        />
      }
      {
        currentStage === BankSelectionStages.ACCOUNT_SELECTION && 
        <AccountSelectionComponent 
          accounts={accounts} 
          onAccountSelect={handleAccountSelect} 
          bicFi={selectedBankBicFi} 
        />
      }
      {
        currentStage === BankSelectionStages.ERROR && 
        <ErrorComponent 
          onRetry={currentRetryAction} 
          onRetryCount={onRetryCount} 
          errorMessage={errorMessage} 
        />
      }
    </div>
  );
}

export default transition(SelectBankPage);