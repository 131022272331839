import Cookies from 'js-cookie';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getAspspList = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/frontend/get-aspsp-list`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const postCreateConsent = async (bicFi) => {
  const instanceId = Cookies.get('instanceId');
  const postData = Cookies.get('postData');
  const postDataJson = JSON.parse(postData);
  const { corporate_id } = postDataJson;

  try {
    const response = await axios.post(`${API_BASE_URL}/frontend/create-consent`, {
      bicFi: bicFi,
      instanceId: instanceId,
      corporateId: corporate_id
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const checkScaStatus = async (consentDto) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/frontend/check-sca-status`, consentDto);
    return response;
  } catch (error) {
    throw error;
  }
};

export const startPollingScaStatus = async (consentDto, handleFinalStatus, setScaData) => {
  const intervalId = setInterval(async () => {
    let scaResponse = await checkScaStatus(consentDto);
    let scaStatus = scaResponse.data.consentDto.scaData.status;

    setScaData(scaResponse.data.consentDto.scaData);

    if (["finalised", "failed", "exempted"].includes(scaStatus)) {
      clearInterval(intervalId);
      handleFinalStatus(scaStatus);
    } else {
      consentDto = scaResponse.data.consentDto;
    }
  }, 1000); // Timer for how often to check if the sca status in milliseconds

  return intervalId;
};

export const getBankAccounts = async (instanceId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/frontend/get-account-list`, {
      headers: {
        'InstanceId': instanceId
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const postSaveSelectedAccount = async (account) => {
  const instanceId = Cookies.get('instanceId');
  try {
    const response = await axios.post(`${API_BASE_URL}/frontend/store-selected-account`, account, {
      headers: {
        'InstanceId': instanceId
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const getAccountSelectionEnabled = async (instanceId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/frontend/get-account-selection-enabled`, {
      headers: {
        'InstanceId': instanceId
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}