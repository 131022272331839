import { useTranslation } from 'react-i18next'
import transition from "../../transition";

function ErrorComponent({ onRetry, onRetryCount, errorMessage }) {
  const { t } = useTranslation()

  return (
    <div className='fixed inset-0 flex flex-col items-center justify-center'>
      <p className='mb-8'>{errorMessage}</p>
      <button className='op-btn' onClick={() => { onRetry(); onRetryCount(); }}>{t('selectBankPage.error.retryButton')}</button>
    </div>
  );
}
export default transition(ErrorComponent);