import React from 'react'
import Input from '../Components/Form/Input'
import transition from '../transition'
import { isValidEmail, reasonableName } from '../validation'
import { useFormData } from '../FormDataContext'
import { useInstanceValidator } from '../InstanceValidator'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

function ContactPage() {
  const { postData, instanceData } = useFormData()
  useInstanceValidator(instanceData)
  const navigate = useNavigate()

  const { t } = useTranslation()

  return (
    <>
      <h1 className='text-center mb-8'>{t('contactPage.title')}</h1>
      <p className='text-center'>{t('contactPage.intro')}</p>

      <form className='max-w-sm mx-auto' onSubmit={e => e.preventDefault()}>
        <Input label={t('contactPage.nameLabel')} id='contact_person_name' value={postData.contact_person_name} />
        <Input label={t('contactPage.emailLabel')} id='contact_person_email' value={postData.contact_person_email} />
        <button type='submit'  className='op-btn w-full' disabled={!isValidEmail(postData.contact_person_email) || !reasonableName(postData.contact_person_name)} onClick={() => navigate('/yourbusiness')}>
          { t('contactPage.continueButtonLabel') }
        </button>
      </form>
    </>
  )
}

export default transition(ContactPage)