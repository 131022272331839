import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

export const useInstanceValidator = (instanceData) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (instanceData.company.corporateId === '') {
      const instanceId = Cookies.get('instanceId');

      if (instanceId && instanceId.length === 36) {
        navigate('/')
        return
      }

      navigate('/x')
      return
    }
  }, [instanceData, navigate])

}