import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// DUMMY FOR MOCKING API CALL DELAYS
export const delay = (time) => {
  return new Promise((resolve) => {
      setTimeout(() => resolve(), time);
  });
}

export const getCompanyInfo = async (instanceId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/frontend/get-company-info`, {
      headers: {
        'InstanceId': instanceId
      }
    })
    return response.data.payload
  } catch (error) {
    return false
  }
}

export const postKycData = async (instanceId, postData) => {
  try {
    const headers = {
      'InstanceId': instanceId
    }
    const response = await axios.post(`${API_BASE_URL}/frontend/store-kyc-data`, postData, { headers })
    return response
  } catch (error) {
    return false
  }
}

export const signKyc = async (instanceId, postData, signing_method) => {
  try {
    const headers = {
      'InstanceId': instanceId
    }
    const response = await axios.post(`${API_BASE_URL}/frontend/sign-kyc`, {
      signing_method: signing_method,
      message: `I'm hereby signing the KYC form for ${postData?.corporate_id ?? ""} to use services from Open Payments.`,
    }, { headers })
    return response
  } catch (error) {
    return false
  }
}

export const mockSignKyc = async (instanceId, postData, signing_method) => {
  try {
    const headers = {
      'InstanceId': instanceId
    }
    const response = await axios.post(`${API_BASE_URL}/frontend/mock-sign-kyc`, {
      signing_method: signing_method,
      message: `I'm hereby signing the KYC form for ${postData?.corporate_id ?? ""} to use services from Open Payments.`,
    }, { headers })
    return response
  } catch (error) {
    return false
  }
}

export const getSigningStatus = async (instanceId) => {
  try {
    const headers = {
      'InstanceId': instanceId
    }
    const response = await axios.get(`${API_BASE_URL}/frontend/get-signing-status`, { headers })
    return response
  } catch (error) {
    return false
  }

}

export const processKycData = async (instanceId) => {
  try {
    const headers = {
      'InstanceId': instanceId
    }
    const response = await axios.post(`${API_BASE_URL}/frontend/process-kyc-data`, {}, { headers })
    return response.status
  } catch (error) {
    return false
  }
}