import React from 'react'
import transition from '../transition'
import RadioGroup from '../Components/Form/RadioGroup'
import Dropdown from '../Components/Form/Dropdown'
import Input from '../Components/Form/Input'
import MultiSelect from '../Components/Form/MultiSelect'
import countries from '../assets/data/countryDataWithTranslations.json'
import { useFormData } from '../FormDataContext'
import { useInstanceValidator } from '../InstanceValidator'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

function YourBusinessPage() {
  const { instanceData, postData, updatePostData } = useFormData()
  useInstanceValidator(instanceData)
  const navigate = useNavigate()

  const { revenue_amount, 
    currency, 
    transaction_frequency, 
    supplier_payments_amount, 
    make_international_payments, 
    international_payments_per_year, 
    payment_recipient_countries} = postData

  const validForm = () => {
    var firstSection = revenue_amount && currency && transaction_frequency && supplier_payments_amount && make_international_payments !== null

    if (firstSection && make_international_payments) {
      var secondSection = international_payments_per_year && payment_recipient_countries && payment_recipient_countries.length > 0
      return firstSection && secondSection
    }

    return firstSection
  }

  const countrySelectHandler = e => {
    const v = e.target.value
    const prev = payment_recipient_countries

    if (prev && prev.includes(v) ) {
      countryDeleteHandler(v)
      return
    }

    prev.push(v)
    updatePostData({ payment_recipient_countries: prev.filter(x => x !== '')})
  }

  const countryDeleteHandler = v => {
    const prev = payment_recipient_countries
    updatePostData({ payment_recipient_countries: prev.filter(x => x !== v)})
  }

  const { t, i18n } = useTranslation()

  return (
    <div>
      <h1 className='mb-8 text-center'>{t('yourBusinessPage.title')}</h1>

      <div className='block my-8'>
        <div className='mx-auto max-w-md'>
          <p>{t('yourBusinessPage.intro')}</p>
        </div>
      </div>

      <p className='font-medium max-w-md mx-auto -mb-7'>{t('yourBusinessPage.revenueLabel')}</p>
      <form onSubmit={e => e.preventDefault()}>
        <div className='grid grid-cols-[2fr_1fr] md:grid-cols-[3fr_1fr] gap-4 max-w-md mx-auto items-end'>
          <Input
            type='text'
            id='revenue_amount'
            formatNumeric={true}
            value={postData.revenue_amount}
          />

          <Dropdown
            collection={countries
              .filter((obj, index, self) => self.findIndex(innerObj => innerObj.currency === obj.currency) === index)
              .map(x => ({
                id: x.currency,
                value: x.currency,
                label: x.currency
              }))
              .filter(x => x.label !== '')
              .sort((a, b) => a.label > b.label ? 1 : -1)}
            value={currency}
            onChange={e => updatePostData({ currency: e.target.value })}
          />
        </div>

        <RadioGroup
          heading={t('yourBusinessPage.transactionFrequencyLabel')}
          name='transaction_frequency'
          value={postData.transaction_frequency}
          radios={[
            {
              id: 'daily',
              label: t('transactionFrequency.daily'),
              value: 'Daily'
            },
            {
              id: 'weekly',
              label: t('transactionFrequency.weekly'),
              value: 'Weekly'
            },
            {
              id: 'monthly',
              label: t('transactionFrequency.monthly'), // Note the typo in 'Monthly'
              value: 'Monthly'
            },
            {
              id: 'yearly',
              label: t('transactionFrequency.aFewTimesPerYear'),
              value: 'AFewTimesPerYear'
            }
          ]}
        />

        <RadioGroup
          heading={t('yourBusinessPage.supplierPaymentsLabel')}
          name='supplier_payments_amount'
          value={postData.supplier_payments_amount}
          radios={[
            {
              id: 'pay-1',
              label: `1 - 1 000 ${postData.currency}`,
              value: 'OneToOneK'
            },
            {
              id: 'pay-1000',
              label: `1 000 - 10 000 ${postData.currency}`,
              value: 'OneKToTenK'
            },
            {
              id: 'pay-5000',
              label: `10 000 - 50 000 ${postData.currency}`,
              value: 'TenKToFiftyK'
            },
            {
              id: 'pay-10000',
              label: `50 000 ${postData.currency} ` + t('supplier_payments_amount.andAbove'),
              value: 'MoreThan50K'
            }
          ]}
        />

        <RadioGroup
          heading={t('yourBusinessPage.internationalPaymentsLabel')}
          name='make_international_payments'
          value={postData.make_international_payments === false ? 'No' : postData.make_international_payments === true ? 'Yes' : null}
          onChange={e => updatePostData({ make_international_payments: e.target.value === 'Yes' })}
          radios={[
            {
              id: 'yes',
              label: t('make_international_payments.yes'),
              value: 'Yes'
            },
            {
              id: 'no',
              label: t('make_international_payments.no'),
              value: 'No'
            }
          ]}
        />

        {postData.make_international_payments === true ? (
          <>
            <Input
              label={t('yourBusinessPage.internationalPaymentsNumberLabel')}
              type='text'
              id='international_payments_per_year'
              formatNumeric={true}
              value={postData.international_payments_per_year}
            />

            <MultiSelect
              heading={t('selectCountries.heading')}
              note={t('selectCountries.note')}
              label={t('selectCountries.label')}
              selectedLabel={t('selectCountries.selectedLabel')}
              value={payment_recipient_countries.filter(x => x !== '')}
              onChange={countrySelectHandler}
              deleteHandler={countryDeleteHandler}
              collection={countries.map(x => ({
                value: x.name['en'],
                label: x.name[i18n.language]
              })).sort((a, b) => a.label.localeCompare(b.label, 'sv'))}
            />
          </>
        ) : false}

        <div className='text-center'>
          <button type='submit' onClick={() => navigate('/beneficialowner')} className='op-btn' disabled={!validForm()}>

            {t('yourBusinessPage.correctButtonLabel')}
          </button>
        </div>
      </form>
    </div>
  )
}

export default transition(YourBusinessPage)