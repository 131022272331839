import { useState, useEffect } from 'react';
import transition from '../transition';
import Checkbox from '../Components/Form/Checkbox';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../Components/LanguageSelector';
import { useNavigate } from 'react-router-dom';
import { useFetchCompanyInfoData } from '../CompanyInfoData';
import { SpinnerCircular } from 'spinners-react';

function TermsPage() {
  const [terms, setTerms] = useState(false);
  const [rights, setRights] = useState(false);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { fetchGeneralCompanyInfo } = useFetchCompanyInfoData()
  const { t } = useTranslation();

  const prepareKyc = async () => {
    setLoading(true)
    await fetchGeneralCompanyInfo()
    setLoading(false)
  }  

  useEffect(() => {
    prepareKyc()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <h1 className='mb-8 text-center'>{t('termsPage.title')}</h1>

      <p className='h4 text-center mb-8'>{t('termsPage.intro')}</p>
      <LanguageSelector />
      <div className='max-w-md mx-auto'>
        <p>{t('termsPage.termsInShort')}</p>
        <ul className='list-disc ml-4 mb-8'>
          <li>{t('termsPage.term1')}</li>
          <li>{t('termsPage.term2')}</li>
          <li>{t('termsPage.term3')}</li>
          <li>{t('termsPage.term4')}</li>
        </ul>
      
        <form onSubmit={e => e.preventDefault()}>
          <div className="mb-8">
            <Checkbox value={ terms } onChange={e => setTerms(e.target.checked)} id='terms-checkbox' label={t('termsPage.acceptTermsLabel')} />
            <Checkbox value={ rights } onChange={e => setRights(e.target.checked)} id='rights-checkbox' label={t('termsPage.signatoryRightsLabel')} />
          </div>

          <button onClick={() => navigate('/verification')} type='submit' className='op-btn w-full mb-8 relative' disabled={ loading || !terms || !rights } >
            <span className={`${loading ? 'opacity-0' : ''}`}>
              {t('termsPage.moveOnToKYC')}
            </span>
            <span className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
              <SpinnerCircular size={28} thickness={180} color='rgba(255,255,255,1)' speed={118} secondaryColor='rgba(255,255,255,0)' enabled={loading} />
            </span>
          </button>

          <a href='https://www.openpayments.io/privacypolicy' target='_blank' rel='noreferrer' className='text-center block'>
            {t('termsPage.readFullTermsLink')}
          </a>
        </form>
      </div>
    </div>
  );
}

export default transition(TermsPage);