import React from 'react'
import { useFormData } from '../../FormDataContext'

function RadioGroup({ heading, name, onChange, value, radios }) {
  const { handleGenericPostInputChange } = useFormData()

  return (
    <div className='text-left max-w-md mx-auto mb-8'>
      <p className='font-medium'>{ heading }</p>
      { radios.map(x =>
        <div key={x.id}>
          <label htmlFor={ x.id } className='block relative pl-8 cursor-pointer select-none' >
            { x.label }
            <input type='radio' name={ name } id={ x.id } value={ x.value ?? x.label } className='sr-only cursor-pointer peer' onChange={onChange ?? handleGenericPostInputChange} defaultChecked={ value === x.value || value === x.label } />
            <span className="absolute top-[2px] left-0 h-4 w-4 border border-op-darkest-blue rounded-full after:content-[''] after:w-2.5 after:h-2.5 after:bg-op-darkest-blue after:rounded-full after:top-[2px] after:left-[2px] after:absolute after:hidden peer-checked:after:block" />
          </label>
        </div>
      )}
    </div>
  )
}

export default RadioGroup