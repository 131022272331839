import { React } from 'react'
import './styles/index.css'
import { Routes, Route, useLocation } from 'react-router-dom'

// import StartPage from './pages/00_StartPage.js'
import TermsPage from './pages/01_TermsPage'
import VerificationPage from './pages/02_VerificationPage'
import ContactPage from './pages/03_ContactPage'
import YourBusinessPage from './pages/04_YourBusinessPage'
import BeneficialOwnerPage from './pages/05_BeneficialOwnerPage'
import PepPage from './pages/06_PepPage'
import SummaryPage from './pages/07_SummaryPage'
import SigningPage from './pages/08_SigningPage'
import SelectBankPage from './pages/09_SelectBankPage.js'
import WelcomePage from './pages/10_WelcomePage.js'
import ErrorPage from './pages/ErrorPage'
import WindowCloser from './pages/WindowCloser'

import Progress from './Components/Progress'
import { formSteps } from './models/formSteps'

import { AnimatePresence } from 'framer-motion'
import { FormDataProvider } from './FormDataContext'
import BlankPage from './pages/BlankPage'
import { useTranslation } from 'react-i18next'

function App() {
  const location = useLocation()
  const { i18n } = useTranslation()

  return (
    <FormDataProvider>
      <div className='App pt-10 pb-20 sm:py-20 w-full font-primary bg-white'>
        <div className='container'>
          <Progress location={location} steps={formSteps[i18n.language]} showNumbers={false} />
          <AnimatePresence mode='wait'>
            <Routes location={location} key={location.pathname}>
              {/* KYC FORM */}
              {/* <Route index element={<StartPage />} /> */}
              <Route index element={<TermsPage />} />
              <Route path='/verification' element={<VerificationPage />} />
              <Route path='/contact' element={<ContactPage />} />
              <Route path='/yourbusiness' element={<YourBusinessPage />} />
              <Route path='/beneficialowner' element={<BeneficialOwnerPage />} />
              <Route path='/pep' element={<PepPage />} />
              <Route path='/summary' element={<SummaryPage />} />
              <Route path='/signing' element={<SigningPage />} />
              <Route path='/selectbank' element={<SelectBankPage />} />
              <Route path='/welcome' element={<WelcomePage />} />

              {/* Errors and other routes */}
              <Route path='/error' element={<ErrorPage />} />
              <Route path='/windowcloser' element={<WindowCloser />} />
              <Route path='*' element={<BlankPage />} />
            </Routes>
          </AnimatePresence>
        </div>
      </div>
    </FormDataProvider>
  )
}

export default App
