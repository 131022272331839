import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useKycPlusValidator = (instanceData) => {
  const navigate = useNavigate();

  useEffect(() => {
    async function validateKycPlus() {

        if (!instanceData.kycPlus) {
          navigate('/x');
        }
    }

    validateKycPlus();
  }, [instanceData, navigate]);
}
