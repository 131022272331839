import transition from '../transition'
import { useFormData } from '../FormDataContext'
import { Link } from 'react-router-dom'
import { formatPsuId, formatName, isCeo, isChairman } from '../mapHelper'
import { useInstanceValidator } from '../InstanceValidator'
import { useTranslation } from 'react-i18next'

function SummaryPage() {
  const { instanceData, postData } = useFormData()
  useInstanceValidator(instanceData)

  const { t } = useTranslation()

  return (
    <div>
      <h1 className='text-center mb-8'>{t('summaryPage.pageTitle')}</h1>

      <div className='max-w-md mx-auto'>
        <p className='font-medium text-center mb-8'>{t('summaryPage.summaryText')}</p>
        <div className='max-w-sm mx-auto'>
          <div className='mb-4'>
            <span className='font-medium block'>{t('summaryPage.companyInfo')}</span>
            {instanceData.company.corporateId}<br />
            {instanceData.company.companyName}<br />
            {instanceData.company.streetAddress}<br />
            {instanceData.company.postalCode} {instanceData.company.city}<br />
            {instanceData.company.country}<br />
          </div>

          <div className='mb-4'>
            <span className='font-medium block'>{t('summaryPage.financialInfo')}</span>
            {t('summaryPage.revenueLevel')} {postData.revenue_amount}<br />
            {t('summaryPage.transactionFrequency')} {t(`transactionFrequency.${postData.transaction_frequency.toLowerCase()}`, postData.transaction_frequency)}<br />
            {t('summaryPage.internationalPayments')} {postData.make_international_payments ? t('summaryPage.yesLabel') : t('summaryPage.noLabel')}<br />
            {postData.make_international_payments ? (
              <>
                {t('summaryPage.estimatedPaymentsPerYear')} {postData.international_payments_per_year}<br />
                {t('summaryPage.paymentRecipientCountries')} {postData.payment_recipient_countries.join(', ')}
              </>
            ) : null}
          </div>

          <span className='font-medium block'>{t('summaryPage.personalInfo')}</span>

          { instanceData.company.beneficialOwners.map((x, i) => (
            <div key={x.psuId} className='block'>
              {formatName(x.name)}, {formatPsuId(x.psuId)} {postData[`beneficial_owners_${i}_pep`] && t('summaryPage.pepLabel')}
            </div>
          ))}
          
          { instanceData.company.beneficialOwners.length === 0 && instanceData.company.boardMembers.map(x => {
            if (isCeo(x.role)) {
              return (
                <div className='block' key={ x.psuId }>
                  {x.name}, {formatPsuId(x.psuId)} {postData.ceo_pep && t('summaryPage.pepLabel')}
                </div> 
              )
            }
            if (isChairman(x.role)) {
              return (
                <div className='block' key={ x.psuId }>
                  {x.name}, {formatPsuId(x.psuId)} {postData.chairman_pep && t('summaryPage.pepLabel')}
                </div> 
              )
            }
            return null
          })}

          { postData.alternative_beneficial_owner_ssn !== '' &&
            <div key={postData.alternative_beneficial_owner_ssn} className='block'>
              {postData.alternative_beneficial_owner_name}, {postData.alternative_beneficial_owner_ssn} {postData.alternative_beneficial_owner_pep && t('summaryPage.pepLabel')}
            </div>
          }

          <span className='font-medium block mt-4'>{t('summaryPage.contactInfo')}</span>
          {postData.contact_person_name}<br />
          {postData.contact_person_email}
        </div>

        <Link to='/signing' className='op-btn text-center w-full mt-12'>
          {t('summaryPage.looksGoodButton')}
        </Link>
      
      </div>
    </div>
  )
}

export default transition(SummaryPage)