import React from 'react'
import { useFormData } from '../FormDataContext'
import { useInstanceValidator } from '../InstanceValidator'
import transition from '../transition'
import { useTranslation } from 'react-i18next'
import { usePepData } from '../PepData'
import { useNavigate } from 'react-router-dom'

function PepPage() {
  const { instanceData } = useFormData()
  useInstanceValidator(instanceData)
  const { RenderPepDropdown, validPepForm, anyoneIsPep } = usePepData()
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div>
      <h1 className='text-center mb-8'>{t('pepPage.pageTitle')}</h1>
      <div className='max-w-md mx-auto'>
        <p>{t('pepPage.questionText')}</p>
        <RenderPepDropdown />
        { anyoneIsPep() && 
          <p className='smaller mt-4'>
           { t('pepPage.pepInfoDisclaimer') }
          </p>
        }
        <button onClick={() => navigate('/summary')} className='op-btn w-full mt-8' disabled={ !validPepForm() }>
          {t('companyInfoPage.button')}
        </button>
      </div>
    </div>
  )
}

export default transition(PepPage)