import countries from './assets/data/countrydata.json'
import { initialPostObject } from './models/post'

export const getCountryNameByCode = code => 
  code ? countries.find(x => x.countryCode === code).name : ""

export const formatName = name => {
  if (!name) return name 

  if (name.includes(', ')) {
    const split = name.split(', ')
    return `${split[1]} ${split[0]}`
  }
  return name
}

export const formatPsuId = psuId => {
  if (!psuId) return psuId

  if (psuId.length === 10) {
    return `${psuId.substring(0,6)}-${psuId.substring(6)}`
  }

  if (psuId.length === 12) {
    return `${psuId.substring(0,8)}-${psuId.substring(8)}`
  }
  return psuId
}

function filterArrayByProperty(arr, property) {
  return arr.reduce((unique, obj) => {
    if (!unique.some(item => item[property] === obj[property])) {
      unique.push(obj);
    }
    return unique;
  }, []);
}

function getDate(originalString) {
  if (!originalString) return originalString

  const year = originalString.substring(0, 4);
  const month = originalString.substring(4, 6);
  const day = originalString.substring(6);

  return `${year}-${month}-${day}`;
}

export const containsBeneficialBoardMember = board => {
  if (board.length > 0) {
    for (const boardMember of board) {
      if (isCeo(boardMember.role) || isChairman(boardMember.role)) {
        return true
      }
    }
  }
  return false
}

const isChairman = (role) => [
  'chairman'
].includes(role.toLowerCase())

const isCeo = (role) => [
  'ceo'
].includes(role.toLowerCase())


function mapPostDataWithCompanyInfo(companyInfo) {
  const postData = initialPostObject
  postData['corporate_id'] = companyInfo.corporateId
  postData['currency'] = countries.find(x => x.countryCode === companyInfo.country)?.currency ?? 'SEK' 

  return postData
}

export {
  getDate,
  filterArrayByProperty,
  mapPostDataWithCompanyInfo,
  isChairman,
  isCeo
};
