import { Link } from 'react-router-dom'
import * as mapHelper from '../mapHelper.js'
import Transition from '../transition.js'
import { useFormData } from '../FormDataContext.js'
import { useInstanceValidator } from '../InstanceValidator.js'
import { useTranslation } from 'react-i18next'

function VerificationPage() {
  const { instanceData } = useFormData()
  useInstanceValidator(instanceData)

  const {
    companyName,
    streetAddress,
    postalCode,
    city,
    country,
    companyDescription,
    businessType,
    companyStatus,
    companyRegistrationDate,
    ftax,
    companyType,
    isSendingLetterOfDisclosure
  } = instanceData.company

  const { t } = useTranslation()

  return (
    <div>
      <div className='mb-12 text-center'>
        <h1 className='mb-4'>{t('verificationPage.title')}</h1>
        <h2 className='h3 mb-8'>{t('verificationPage.intro')}</h2>
        <div className='mb-8'>
          <p><span className='font-medium'>{t('verificationPage.officialAddress')}</span><br />
            {companyName}<br />
            {streetAddress}<br />
            {postalCode} {city}<br />
            {mapHelper.getCountryNameByCode(country)}</p>
        </div>

        { isSendingLetterOfDisclosure &&
          <p className='font-medium text-center'>{t('verificationPage.letterNotice')}</p>
        }

        <Link to='/contact' className='op-btn'>
          {t('verificationPage.correctButtonLabel')}
        </Link>
      </div>

      <div>
        <h3 className='h3 mb-4'>{t('verificationPage.businessSectionTitle')}</h3>

        <div className='md:grid md:grid-cols-2 md:gap-16 mb-8'>
          { (companyDescription || businessType) && (
            <div className=''>
              { companyDescription && (
                <>
                <h4 className='mb-2'>{t('verificationPage.businessDescription')}</h4>
                <p>
                  {companyDescription}
                </p>
                </>
              )}
              {businessType && (
                <>
                  <h4 className='mb-2'>{t('verificationPage.officialBusinessType')}</h4>
                  <p>
                    {businessType}
                  </p>
                </>
              )}
            </div>
          )}
          <div>
            <h4 className='mb-2'>{t('verificationPage.companyStatus')}</h4>
            { companyStatus && (
              <p className='mb-1'>
                <span className='font-medium block'>{t('verificationPage.companyStatus')}:</span>
                {t(`mappedValues.companyStatus.${companyStatus}`, companyStatus)}
              </p>
            )}
            { companyRegistrationDate && (
              <p className='mb-1'>
                <span className='font-medium block'>{t('verificationPage.companyRegistrationDate')}:</span>
                {mapHelper.getDate(companyRegistrationDate)}
              </p>
            )}
            { ftax && (
              <p className='mb-1'>
                <span className='font-medium block'>{t('verificationPage.fTax')}:</span>
                {t(`mappedValues.ftax.${ftax}`, ftax)}
              </p>
            )}
            { companyType && (
              <p className='mb-1'>
                <span className='font-medium block'>{t('verificationPage.legalIdentityStatus')}:</span>
                {t(`mappedValues.companyType.${companyType}`, companyType)}
              </p>
            )}
          </div>
        </div>
        <div className='mb-8'>
          <h4 className='mb-2'>{t('verificationPage.notCorrectSectionTitle')}</h4>
          <p className='mb-8'>
            {t('verificationPage.notCorrectExplanation')}
          </p>
          <h4 className='mb-2'>{t('verificationPage.updatedDataTitle')}</h4>
          <p>
            {t('verificationPage.updatedDataExplanation1')}
          </p>
          <p>
            {t('verificationPage.updatedDataExplanation2')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Transition(VerificationPage)