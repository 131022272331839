import { useFormData } from '../../FormDataContext'

function Input({ id, name, label, value, placeholder, type = 'text', onChange, formatNumeric = false }) {
  const { handleGenericPostInputChange, updatePostData } = useFormData()

  const formatNumberWithDelimiter = value => (parseFloat(value.replace(/[^0-9]/g, '')) || 0).toLocaleString('sv-SE')

  // Event handler for input changes
  const handleFormatedNumericInputChange = e => {
    const { name, value} = e.target;
    updatePostData({ [name]: formatNumberWithDelimiter(value) })
  }

  return (
    <div className='block my-8'>
      <div className='mx-auto max-w-md'>
        { label ?
          <label htmlFor={ id } className='font-medium text-left block ml-1 mb-2'>{ label }</label>
          : null
        }
        <input id={ id } 
          name={ name ?? id } 
          type={ type } 
          value={ value || '' } 
          placeholder={ placeholder || ''}
          onChange={ formatNumeric ? handleFormatedNumericInputChange : (onChange ?? handleGenericPostInputChange) } 
          className={`shadow-md hover:shadow-lg hover:border-grey-2 transition-all border border-grey-1 rounded-md py-4 px-6 block w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
          />
      </div>
    </div>
  )
}

export default Input