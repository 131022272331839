import { QRCodeCanvas } from "qrcode.react"
import { useTranslation } from "react-i18next"
import { getSigningStatus } from "../../clients/kycClient"
import useIntervalAsync from "../../utils/useIntervalAsync"
import { useState, useCallback } from "react"
import { SigningStages } from "../../models/SigningStages"
import BankIdLogo from '../../assets/images/BankID_logo.svg'

function SwedishBankId({ initialQr, autostartToken, cancelSigning, setErrorMessage, setStageHandler, instanceData }) {
  const { t } = useTranslation()
  const [qr, setQr] = useState(initialQr)

  const getStatus = useCallback(async () => {
    const response = await getSigningStatus(instanceData.instanceId)

    if (response === undefined || response === 500) {
      setErrorMessage(t('externalSigning.generalError'))
      setStageHandler(SigningStages.SELECTMETHOD)
      return
    }

    if (response.data.status === 'SUCCESS') {
      setStageHandler(SigningStages.PROCESSINGDATA)
      return
    }

    if (response.data.idpData) {
      setQr(response.data.idpData.qrData)
      return
    }
  }, [instanceData.instanceId, setStageHandler])

  useIntervalAsync(getStatus, 1000)

  return (
    <div className="sm:flex items-center mt-12">

      <div className="block w-[320px] mx-auto">
        <div className="border border-gray-4 shadow-lg p-6 rounded-lg w-full flex flex-col items-center">
          <QRCodeCanvas value={qr}
            size={260}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"H"}
          />
          <img src={BankIdLogo} width={100} className="mx-auto mt-6" />
        </div>

        <a href={`bankid:///?autostarttoken=${autostartToken}&redirect=${window.location.href}`} className="block mt-4 text-center sm:hidden">
          {t('swedishBankId.startBankIdApp')}
        </a>
      </div>

      <div className="max-w-[360px] mx-auto mt-6 sm:mt-0 sm:ml-4">
        <p className="font-medium">{t('swedishBankId.instructions')}</p>
        <ul className="list-decimal ml-2 mb-6 text-left pl-3">
          <li>{t('swedishBankId.instructionSteps.one')}</li>
          <li>{t('swedishBankId.instructionSteps.two')}</li>
          <li>{t('swedishBankId.instructionSteps.three')}</li>
          <li>{t('swedishBankId.instructionSteps.four')}</li>
        </ul>

        <hr className="mb-6" />

        {/* TODO: Look into a flow of redirecting back. Requires a little more work. Setting redirect to null for now. */}
        <a href={`bankid:///?autostarttoken=${autostartToken}&redirect=null`} className="hidden sm:block">
          {t('swedishBankId.startBankIdApp')}
        </a>

        <button className="block my-6 cursor-pointer font-medium text-op-blue underline" onClick={cancelSigning}>{t('bankIdSigningPage.cancelSigning')}</button>
      </div>
    </div>
  )

}

export default SwedishBankId