import React, { useState, useRef, useEffect } from 'react'

function MultiSelect({ heading, label, collection, value, onChange, deleteHandler, note  }) {
  const [open, setOpen] = useState(false)
  const wrapperRef = useRef(null)

  // Close dropdown when clicking outside of it
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])


  const getLabelByValue = x => {
    for (const obj of collection) {
      if (obj.value === x) {
        return obj.label
      }
    }
  }

  return (
    <div className='block my-8'>
      <div className='mx-auto max-w-md' ref={wrapperRef}>
        <p className='font-medium'>{ heading }</p>
        <div className='shadow-lg border border-grey-1 rounded-md relative cursor-pointer mb-4'>
          <span className={`border-[12px] border-grey-7 border-l-white border-r-white border-b-white absolute right-6 top-6 origin-top transition-all ${open ? 'rotate-180 translate-y-[8px]' : '' }`} />
          <span className='block p-4 text-grey-7 text-left' onClick={() => setOpen(() => !open)}>
            { label }
          </span>
          <div className={`max-h-48 overflow-scroll ${open ? '' : 'hidden'}`}>
            {/* This part is not fully generic yet */}
            { collection.map(x => (
              <label htmlFor={ x.value } className='block relative text-left cursor-pointer' key={x.value}>
                <input type='checkbox' name='id' id={ x.value } value={ x.value } className='opacity-0 absolute peer' onChange={onChange} checked={ value && value.includes(x.value) }/> 
                <span className='peer-checked:bg-op-lightest-blue block p-2 hover:bg-grey-1'>
                  { x.label }
                </span>
              </label>
            ))}
          </div>
        </div>
        <div className='text-left'>
          <p className='font-medium'>{ note }</p>
          { value && value.map(x => (
            <span className='bg-op-darkest-blue text-white py-2 px-4 m-1 inline-block rounded-full font-medium ' key={x}>
              { getLabelByValue(x) } 
              <div className='inline-block ml-2 relative w-4 h-5 cursor-pointer p-2 ' onClick={() => deleteHandler(x)}>
                <span className='block absolute w-[1px] h-5 rotate-45 left-0 top-0 bg-white origin-bottom' />
                <span className='block absolute w-[1px] h-5 -rotate-45 bg-white origin-bottom right-0 top-0' />
              </div>
            </span>
          ))} 
        </div>
      </div>
    </div>
  )
}
  
export default MultiSelect