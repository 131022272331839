function Checkbox({ value, onChange, label, id }) {
  return (
    <div className='block mb-1'>
      <input className='op-checkbox mr-2 invisible absolute peer' type='checkbox' value={value || false} id={id} onChange={onChange}/>
      
      <label htmlFor={id} className='flex items-start peer-checked:[&>span>span]:opacity-100 peer-checked:[&>span]:bg-op-blue cursor-pointer select-none text-left'>
        <span className='w-4 h-4 mt-[2px] flex-shrink-0 block mr-2 bg-grey-2 overflow-hidden relative transition-all'>
          <span className='absolute w-4 h-4 items-center opacity-0 flex transition-all'>
            <span className='w-[300%] h-[2px] bg-op-white block rotate-45 origin-center'></span>
          </span>
          <span className='absolute w-4 h-4 items-center opacity-0 flex transition-all'>
            <span className='w-[300%] h-[2px] bg-op-white block -rotate-45 origin-center'></span>
          </span>
        </span>
        <div>
          { label }
        </div>
      </label>
    </div>
  )
}

export default Checkbox