import bankMapping from '../../assets/images/bank_logos'
import { useTranslation } from 'react-i18next'
import transition from '../../transition';

function ListBanksComponent({ banks, onBankSelect }) {
  const { t } = useTranslation()

  const updatedBanks = banks
    .filter(bank => bank.bicFi.includes('SE'))
    .map(bank => {
      const bankCode = Object.keys(bankMapping).find(code => bank.bicFi.includes(code));
      if (bankCode) {
        return {
          ...bank,
          name: bankMapping[bankCode].name,
          logoUrl: bankMapping[bankCode].logo,
        };
      }
      return null;
    })
    // Filter out banks that did not match the bankMapping list
    // This is so the different banks are uniform and have the same rounded logo style with their name written out
    .filter(bank => bank != null);

  return (
    <div className='mx-auto max-w-md flex flex-col bank-list'>
      <div>
        <div className='text-center'>
          <h2 className='mb-8'>{t('selectBankPage.listBanks.pageTitle')}</h2>
          <h2 className='mb-16 text-op-blue'>{t('selectBankPage.listBanks.pageStep')}</h2>
        </div>
        <div className='max-w-sm'>
          <p className='mb-8 font-medium'>{t('selectBankPage.listBanks.pageInfo')}</p>
          <h4 className='font-bold mb-4'>{t('selectBankPage.listBanks.pageSelect')}</h4>
        </div>
      </div>

      <div className='overflow-y-auto -mb-20'>
        <ul className='list-none'>
          {updatedBanks
            .sort((a, b) => a.name.localeCompare(b.name)) // Sort banks alphabetically by name
            .map((bank) => (
              <li key={bank.bicFi} className={`first:mt-10 mb-4`}>
                <button onClick={() => onBankSelect(bank.bicFi)} className='flex items-center'>
                  <img src={bank.logoUrl} alt={bank.name} className='h-20 mr-4 ml-10'/>
                  <p className='font-bold mb-0'>{bank.name}</p>
                </button>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
}

export default transition(ListBanksComponent);