import { SpinnerDotted } from "spinners-react";
import { useTranslation } from 'react-i18next'
import transition from "../../transition";

function PageSpinnerComponent() {
  const { t } = useTranslation()

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center">
      <SpinnerDotted size={100} thickness={135} speed={100} color='#509EF8' className='mx-auto my-8' />
      <p>{t('pageSpinnerComponent.pageText')}</p>
    </div>
  );
}

export default transition(PageSpinnerComponent);