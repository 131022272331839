function BigButton({ onClickHandler, iconSrc, label }) {
  return (
    <button
      className="border-2 border-gray-200 bg-gray-50 p-6 rounded-md flex items-center w-full mb-6 hover:border-op-lighter-blue relative overflow-hidden"
      onClick={onClickHandler}>
      { iconSrc && <img src={iconSrc} alt={label} className='mr-2 h-[35px] object-contain w-[60px]' /> } 
      { label }
    </button>
  )
}

export default BigButton