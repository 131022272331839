import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

function Progress({ location, steps, showNumbers = true }) {
  const [backwards, setBackwards] = useState(false)
  const navigate = useNavigate()
  const { pathname } = location

  const goBackwards = x => {
    if (visited(x.path[0])) {
      setBackwards(true)
      navigate(x.path[0])
      setTimeout(() => setBackwards(false), 500)
    }
  }

  const visited = x => steps.some(v => v.path.includes(pathname)) && steps.findIndex(v => v.path.includes(x)) <= steps.findIndex(v => v.path.includes(pathname))
  return steps.some(x => x.path.includes(pathname)) ? (
    <div className="fade-in">
      <div className="list-decimal flex justify-between items-center text-xs list-inside mb-8 sm:mb-16 relative">
        { steps.map((x, i) => (
          <div className={`-mr-[1px] -ml-[1px] relative transition-all duration-300 ${visited(x.path[0]) ? 'cursor-pointer' : ' text-grey-4' }`} onClick={() => goBackwards(x)} key={x.label}>
            <span className={`relative z-10 w-4 h-4 rounded-full transition-all text-white ${ backwards ? '' : 'delay-200' } ease-out flex justify-center align-center bg-gray-300 ${visited(x.path[0]) ? 'font-bold bg-op-blue' : ''}`}>{ showNumbers ? i + 1 : null }</span>
            <span className="absolute top-5 text-center -translate-x-[50%] left-[50%] hidden sm:inline">{ x.label }</span>
          </div>
        ))}
        <div className={`h-1 w-full bg-grey-2 absolute z-0 transition-all`}></div>
        <div className={`h-1 bg-op-blue absolute z-0 transition-all duration-300 ease-out`} style={{width: ((100 / (steps.length - 1)) * (steps.findIndex(v => v.path.includes(pathname)))) + '%'}}></div>
      </div>
    </div>
  ) : null
}

export default Progress