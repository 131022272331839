import { useCallback, useEffect, useRef } from 'react'

// This lifesaver hook was found here:
// https://blog.devgenius.io/how-to-better-poll-apis-in-react-312bddc604a4
// It handles async polling in a very beautiful way by preventing calls
// being made before the response has been delivered.

const useIntervalAsync = (fn, ms) => {
  const runningCount = useRef(0)
  const timeout = useRef()
  const mountedRef = useRef(false)

  const next = useCallback(handler => {
    if (mountedRef.current && runningCount.current === 0) {
      timeout.current = window.setTimeout(handler, ms)
    }
  }, [ms])

  const run = useCallback(async () => {
    runningCount.current += 1
    const result = await fn()
    runningCount.current -= 1

    next(run)

    return result
  }, [fn, next])

  useEffect(() => {
    mountedRef.current = true
    run()

    return () => {
      mountedRef.current = false
      window.clearTimeout(timeout.current)
    }
  }, [run])

  const flush = useCallback(() => {
    window.clearTimeout(timeout.current)
    return run()
  }, [run])

  return flush
}

export default useIntervalAsync