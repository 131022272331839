export const initialInstanceObject = {
  instanceId: '',
  company: {
    corporateId: '',
    companyName: '',
    streetAddress: '',
    postalCode: '',
    city: '',
    country: '',
    companyDescription: '',
    companyStatus: '',
    companyRegistrationDate: '',
    ftax: '',
    companyType: '',
    officialBusinessType: '',
    boardMembers: [], // boardMemberObject
    beneficialOwners: [], // beneficialOwnerObject
    isSendingLetterOfDisclosure: false,
    isToBeSignedByHand: false
  },
  signingProvider: '',
  kycPlus: false
}