import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'
import './i18n'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // TODO: Reactive React Strict Mode when app has matured.
  // This is because we don't want to send multiple requests to the creditsafe API that we
  // are paying for per request. As react strict mode runs hooks twice among other things.
    <Router>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </Router>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
