import React, { createContext, useContext, useState } from 'react'
import { initialInstanceObject } from './models/instance'
import { initialPostObject } from './models/post'
import Cookies from 'js-cookie'
const FormDataContext = createContext()

export const useFormData = () => {
  return useContext(FormDataContext)
};

export const FormDataProvider = ({ children }) => {
  const [instanceData, setInstanceData] = useState(initialInstanceObject);
  const [postData, setPostData] = useState(initialPostObject)

  const updateInstanceData = (newData) => {
    const updatedData = { ...instanceData, ...newData }
    setInstanceData(updatedData)
    Cookies.set('instanceData', JSON.stringify(updatedData), {
      path: '/',
      expires: 1/24,
      sameSite: 'strict'
    })
  };

  const updatePostData = (newData) => {
    const updatedData = { ...postData, ...newData }
    setPostData(updatedData)
    Cookies.set('postData', JSON.stringify(updatedData), {
      path: '/',
      expires: 1/24,
      sameSite: 'strict'
    })
  };

  const clearFormData = () => {
    setInstanceData({})
    setPostData({})
    Cookies.remove('instanceData')
    Cookies.remove('postData')
  }

  const handleGenericPostInputChange = e => {
    const { name, value } = e.target
    updatePostData({ [name]: value })
  }

  const handleInputChange = e => {
    const { name, value } = e.target;
    updateInstanceData({ [name]: value });
  }

  return (
    <FormDataContext.Provider value={{ instanceData, updateInstanceData, postData, updatePostData, handleInputChange, clearFormData, handleGenericPostInputChange }}>
      {children}
    </FormDataContext.Provider>
  );
};