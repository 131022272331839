import { postCreateConsent, startPollingScaStatus, getBankAccounts, postSaveSelectedAccount } from '../../clients/bankingClient';

export const handleBankSelection = async ({
  bicFi,
  setCurrentStage,
  setAccounts,
  setSelectedBankBicFi,
  setScaData,
  consentCreated,
  setConsentCreated,
  instanceData,
  setCurrentRetryAction,
  setErrorMessage,
  setRetryCount
}) => {
  const fetchAccounts = async () => {
    try {
      setCurrentStage('LOADING');

      const accountResponse = await getBankAccounts(instanceData.instanceId);
      if (accountResponse.status === 200) {
        setCurrentStage('ACCOUNT_SELECTION');
        setAccounts(accountResponse.data.accounts);
      } else {
        setErrorMessage('Failed to load bank accounts. Please try again.');
        setCurrentStage('ERROR');
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred while loading the bank accounts. Please try again.');
      setCurrentStage('ERROR');
    }
  };

  if (!consentCreated) {
    try {
      const response = await postCreateConsent(bicFi);
      if (response.status === 200) {
        setSelectedBankBicFi(bicFi);
        setScaData(response.data.scaData);
        setCurrentStage('SIGN_CONSENT');
  
        startPollingScaStatus(response.data, async (finalStatusResponse) => {
          if (finalStatusResponse === "finalised") {
            setConsentCreated(true);
            setCurrentRetryAction(() => fetchAccounts);
            setRetryCount(0)
            fetchAccounts();
          } else {
            setErrorMessage('The security authorization process did not complete successfully. Please try again.');
            setCurrentStage('ERROR');
            return;
          }
        }, setScaData);
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred while initiating the consent process. Please try again.');
      setCurrentStage('ERROR');
      return;
    }
  } else {
    fetchAccounts();
  }
};

export const handleAccountSelection = async ({
  account,
  setCurrentStage,
  setErrorMessage
}) => {
  try {
    const response = await postSaveSelectedAccount(account);
    if (response.status === 200) {
      setCurrentStage('COMPLETE');
    } else {
      setErrorMessage('There was an issue with selecting your account. Please try again.');
      setCurrentStage('ERROR');
    }
  } catch (error) {
    setErrorMessage('An unexpected error occurred while selecting your account. Please try again.');
    setCurrentStage('ERROR');
  }
};