import FullLogo from '../assets/images/OP-logotype-regular.svg'
import transition from '../transition'
import { useTranslation } from 'react-i18next'
import { useFetchCompanyInfoData } from '../CompanyInfoData'

function WelcomePage() {
  const { t } = useTranslation()
  const { clearData } = useFetchCompanyInfoData()
  clearData()

  return (
    <div className='text-center'>
      <h1 className='mb-8 text-op-blue'>{t('welcomePage.pageTitle')}</h1>
      <h2 className='mb-8'>{t('welcomePage.readyToGo')}</h2>
      <p className='mb-8'>{t('welcomePage.closeWindowMessage')}</p>
      <img src={FullLogo} width={320} className='mx-auto' alt='Open Payments Europe logotype'/>
    </div>
  )
}

export default transition(WelcomePage)