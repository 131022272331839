import logoDanskeBank from './DanskeBank.svg';
import logoDNB from './DNB.svg';
import logoHandelsbanken from './Handelsbanken.svg';
import logoNordea from './Nordea.svg';
import logoSEB from './SEB.svg';
import logoSwedbank from './SwedbankAndSparbankerna.svg';
import logoLF from './LF.svg';

// Bank name and round svg logo for the current banks that are supported
export const bankMapping = {
  'ESSE': { name: 'SEB', logo: logoSEB },
  'HAND': { name: 'Handelsbanken', logo: logoHandelsbanken },
  'SWED': { name: 'Swedbank', logo: logoSwedbank },
  'NDEA': { name: 'Nordea', logo: logoNordea },
  'DABA': { name: 'Danske Bank', logo: logoDanskeBank },
  'DNBA': { name: 'DNB', logo: logoDNB }
};

export default bankMapping;