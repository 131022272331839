import React, { useState, useRef, useEffect } from 'react'

function Dropdown({ heading, placeholder, collection, value, onChange }) {
  const [open, setOpen] = useState(false)
  const wrapperRef = useRef(null)

  // Close dropdown when clicking outside of it
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const selected = value ? collection.find((x) => value === x.value) : false

  if (collection.length === 0) return

  return (
    <div className='block my-8'>
      <div className='mx-auto max-w-md' ref={wrapperRef}>
        <p className='font-medium'>{heading}</p>
        <div className={`shadow-lg border border-grey-1 relative cursor-pointer select-none min-h-[56px] ${ open ? 'border-b-0 pb-[1px] rounded-t-md' : 'rounded-md'}`}>
          <span className={`border-[12px] border-grey-7 border-l-white border-r-white border-b-white absolute right-6 top-6 origin-top transition-all ${open ? 'rotate-180 translate-y-[8px]' : ''}`}
            onClick={() => setOpen(() => !open)} />
          <span className={`flex p-4 text-left ${value ? 'text-black' : 'text-grey-7'}`}
            onClick={() => setOpen(() => !open)}
          >
            { selected && selected.icon ? <img src={selected.icon} alt={selected.label} className='mr-2 max-h-[25px] object-contain w-[50px]' /> : null}
            {selected ? selected.label : value ?? placeholder ?? '\u00A0'}
          </span>

          <div
            className={`absolute -left-[1px] -right-[1px] -translate-y-[1px] bg-white z-20 max-h-48 overflow-scroll border border-t-0 border-grey-1 rounded-b-md shadow-lg ${
              open ? '' : 'hidden'
            }`}
          >
            {collection.map((x) => (
              <label
                htmlFor={x.id}
                className='p-4 hover:bg-grey-1 cursor-pointer flex items-center'
                onChange={onChange}
                onClick={() => setOpen(false)}
                key={x.id}
              >
                { x.icon ? (
                  <img src={x.icon} alt={x.label} className='mr-2 h-[25px] object-contain w-[50px]' />
                ) : null}
                {x.label}
                <input
                  type='checkbox'
                  value={x.value}
                  id={x.id}
                  defaultChecked={value === x.value}
                  className='sr-only'
                />
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dropdown