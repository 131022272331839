export const initialPostObject = {
  corporate_id: '',
  contact_person_name: '',
  contact_person_email: '',
  make_international_payments: null,
  international_payments_per_year: '',
  transaction_frequency: '',
  payment_recipient_countries: [],
  currency: '',
  revenue_amount: '',
  supplier_payments_amount: '',
  alternative_beneficial_owner_name: '',
  alternative_beneficial_owner_ssn: '',
  alternative_beneficial_owner_pep: null,
  ceo_pep: null,
  chairman_pep: null,
  beneficial_owner_1_pep: null,
  beneficial_owner_2_pep: null,
  beneficial_owner_3_pep: null,
  beneficial_owner_4_pep: null
}