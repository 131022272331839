import { useSearchParams, useNavigate } from "react-router-dom"
import { getCompanyInfo } from "./clients/kycClient"
import { mapPostDataWithCompanyInfo, filterArrayByProperty } from "./mapHelper"
import { useFormData } from "./FormDataContext"
import Cookies from "js-cookie"
// import { useSigning } from "./Signing"

export const useFetchCompanyInfoData = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { updateInstanceData, updatePostData } = useFormData()

  const clearData = async () => {
    Cookies.remove('instanceId')
    Cookies.remove('instanceData')
    Cookies.remove('postData')
  }

  const fetchGeneralCompanyInfo = async () => {
    var instanceId = searchParams.get('instanceId')

    if (instanceId === null || instanceId === '') {
      const sessionInstanceId = Cookies.get('instanceId')

      if (sessionInstanceId && sessionInstanceId.length === 36) {
        instanceId = sessionInstanceId
      }
    }

    if (instanceId === null || instanceId === '') {
      navigate('/x')
      return
    }

    Cookies.set('instanceId', instanceId, {
      path: '/',
      expires: 1/24,
      sameSite: 'strict'
    })

    try {
      const companyInfoResponse = await getCompanyInfo(instanceId)

      if (companyInfoResponse === null || companyInfoResponse.companyName === '') {
        navigate('/error', { state: { errorMessage: 'Couldn\'t retrieve any company info for the current request.'}})
        return
      }

      if (companyInfoResponse.boardMembers.length > 1) {
        companyInfoResponse.boardMembers = filterArrayByProperty(companyInfoResponse.boardMembers, 'psuId');
      }
      
      if (companyInfoResponse.beneficialOwners.length > 1) {
        companyInfoResponse.beneficialOwners = filterArrayByProperty(companyInfoResponse.beneficialOwners, 'psuId');
      }

      updateInstanceData({ 
        instanceId: instanceId, 
        company: companyInfoResponse
      })

      updatePostData(mapPostDataWithCompanyInfo(companyInfoResponse))
    } catch (error) {
      navigate('/error', { state: { errorMessage: 'Service problems.' }})
    }
  }

  return { 
    fetchGeneralCompanyInfo,
    clearData
  }
}