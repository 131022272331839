import { useTranslation } from "react-i18next"
import Dropdown from "../Form/Dropdown"

function PepDropdown({ id, value, onChange, label }) {
  const { t } = useTranslation()

  return (
    <div className='flex items-center'>
      <div className='min-w-[110px] -my-6'>
        <Dropdown
          collection={[
            {
              id: `${id}-yes`,
              value: 'true',
              label: t('pepPage.yesLabel')
            },
            {
              id: `${id}-no`,
              value: 'false',
              label: t('pepPage.noLabel')
            }
          ]}
          value={value !== null && value !== undefined ? value.toString() : null}
          onChange={onChange}
        />
      </div>
      <div className='flex-1 pl-4 font-medium'>
        { label }
      </div>
    </div>
    )
  }
      

export default PepDropdown