import bankMapping from '../../assets/images/bank_logos'
import { useTranslation } from 'react-i18next'
import transition from '../../transition';

function formatBBAN(bban) {
  if (bban.length >= 8) {
    return `${bban.slice(0, 4)}-${bban.slice(4, 8)} ${bban.slice(8)}`;
  }
  return bban; // Return original BBAN format if it doesn't match the expected length
}

function AccountSelectionComponent({ accounts, onAccountSelect, bicFi }) {
  const { t } = useTranslation()

  const bankCode = Object.keys(bankMapping).find(code => bicFi.includes(code));
  const bankDetails = bankMapping[bankCode];

  return (
    <div className='mx-auto max-w-sm flex flex-col account-list'>
      <div className='text-center'>
        <h2 className='mb-8'>{t('selectBankPage.accountSelect.pageTitle')}</h2>
        <h2 className='mb-16 text-op-blue'>{t('selectBankPage.accountSelect.pageStep')}</h2>
      </div>

      <div className='overflow-y-auto -mb-20'>
        <ul className='mx-auto text-center list-none'>
          {accounts.map((account) => (
            <li key={account.resourceId} className='mb-4'>
              <button onClick={() => onAccountSelect(account)} className='flex items-center'>
                <img src={bankDetails.logo} alt={`${bankDetails.name} logo`} className='h-20 mr-4 ml-10' />
                <p className='font-bold mb-0'>{formatBBAN(account.bban)}</p>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default transition(AccountSelectionComponent);