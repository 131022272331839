import { useEffect } from "react"

function WindowCloser() {

  useEffect(() => {
    window.close()
  }, [])


  return (
    <div className="text-center">
      <button className="op-btn" onClick={() => window.close()}>If this window doesn't close automatically, click this button</button>
    </div>
  )

}

export default WindowCloser