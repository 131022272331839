import React from 'react'
import Dropdown from './Form/Dropdown' // Import your Dropdown component
import { useTranslation } from 'react-i18next'

import UnitedStatesFlag from '../assets/images/united-states-flag.png'
import SwedenFlag from '../assets/images/sweden-flag.png'
import NorwayFlag from '../assets/images/norway-flag.png'
import FinlandFlag from '../assets/images/finland-flag.png'

function LanguageSelector() {
  const { i18n } = useTranslation()

  const languages = [
    {
      label: 'English',
      value: 'en',
      id: 'en',
      icon: UnitedStatesFlag
    },
    {
      label: 'Svenska',
      value: 'se',
      id: 'se',
      icon: SwedenFlag
    },
    {
      label: 'Norsk',
      value: 'no',
      id: 'no',
      icon: NorwayFlag
    },
    {
      label: 'Suomi',
      value: 'fi',
      id: 'fi',
      icon: FinlandFlag
    },
  ]

  const currentLanguage = i18n.language

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language)
  }

  return (
    <div>
      <Dropdown
        placeholder='Select Language'
        collection={languages}
        value={currentLanguage}
        onChange={(event) => handleLanguageChange(event.target.value)}
      />
    </div>
  )
}

export default LanguageSelector





