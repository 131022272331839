import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function ErrorPage({ errorMessageParam }) {
  const location = useLocation()
  const errorMessage = errorMessageParam ?? location.state.errorMessage
  const { t } = useTranslation()

  return (
    <div className='text-center'>
      <h1 className='mb-8'>{t('errorPage.pageTitle')}</h1>
      <p className='mb-8'>{t('errorPage.inconvenienceMessage')}</p>
      <p className='text-left p-4 border border-grey-1 rounded-lg'>
        <span className='font-medium'>{t('errorPage.errorMessageLabel')}</span><br />
        {errorMessage}
      </p>
    </div>
  )
}

export default ErrorPage