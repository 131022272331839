import { useState, useEffect } from "react"
import { useFormData } from "./FormDataContext"
import { formatName, formatPsuId, isChairman, isCeo } from "./mapHelper"
import { useTranslation } from "react-i18next"
import PepDropdown from "./Components/Pep/PepDropdown"

export const usePepData = () => {
  const [pepCollection, setPepCollection] = useState([])
  const { instanceData, postData, updatePostData } = useFormData()
  const { t } = useTranslation()

  useEffect(() => {
    setPepCollection(setPepCollectionData)
  }, [postData])

  const validPepForm = () => pepCollection.filter(x => x.value === null).length === 0

  const anyoneIsPep = () => pepCollection.filter(x => x.value === true).length > 0

  const setPepCollectionData = () => {
    const c = []

    if (postData.alternative_beneficial_owner_ssn !== '') {
      c.push({
        id: 'alternative_beneficial_owner',
        value: postData.alternative_beneficial_owner_pep,
        onChange: e => updatePostData({alternative_beneficial_owner_pep: e.target.value === 'true'}),
        label: `${formatName(postData.alternative_beneficial_owner_name)}, ${formatPsuId(postData.alternative_beneficial_owner_ssn)}`
      })
    }

    if (instanceData.company.beneficialOwners.length > 0) {
      instanceData.company.beneficialOwners.map((x, i) => (
        c.push({
          id: `beneficial-${i + 1}`,
          value: postData[`beneficial_owner_${i+1}_pep`],
          onChange: e => updatePostData({ [`beneficial_owner_${i + 1}_pep`]: e.target.value === 'true' }),
          label: `${formatName(x.name)}, ${formatPsuId(x.psuId)}`
        })
      ))
    }

    if (instanceData.company.beneficialOwners.length === 0 && instanceData.company.boardMembers.length > 0) {
      instanceData.company.boardMembers.map(x => {
        if (isCeo(x.role)) {
          c.push({
            id: 'ceo',
            value: postData.ceo_pep,
            onChange: e => updatePostData({ceo_pep: e.target.value == 'true'}),
            label: `${t('pepPage.ceoLabel')}: ${formatName(x.name)}, ${formatPsuId(x.psuId)}`
          })
        }
        if (isChairman(x.role)) {
          c.push({
            id: 'chairman',
            value: postData.chairman_pep,
            onChange: e => updatePostData({chairman_pep: e.target.value == 'true' }),
            label: `${t('pepPage.chairmanLabel')}: ${formatName(x.name)}, ${formatPsuId(x.psuId)}`
          })
        }
      })
    }

    return c
  }

  const RenderPepDropdown = () => { 
    return (
      <>
      { pepCollection.map((x, i) => (
        <PepDropdown
          id={x.id}
          value={x.value}
          onChange={x.onChange}
          label={`${x.label}`}
          key={`pep-${i}`}
          />
      ))} 
      </>
    )
  }
  
  
  return {
    RenderPepDropdown,
    validPepForm,
    anyoneIsPep
  }
}