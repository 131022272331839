import transition from '../transition'
import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { SpinnerCircular } from 'spinners-react'
import BigButton from '../Components/BigButton'
import { useFormData } from '../FormDataContext'
import { useInstanceValidator } from '../InstanceValidator'
import BankIdLogo from '../assets/images/BankID_logo.svg'
import BankIdNoLogo from '../assets/images/BankID_logo_no.svg'
import FinlandFlag from '../assets/images/finland-flag.png'
import { signKyc, postKycData } from '../clients/kycClient'
import SwedishBankId from '../Components/Signing/SwedishBankIdQR'
import ExternalSigning from '../Components/Signing/ExternalSigning'
import MockSigning from '../Components/Signing/MockSigning'
import ProcessingData from '../Components/Signing/ProcessingData'
import { useNavigate } from 'react-router-dom'
import { ErrorMessage } from '../Components/Typography/ErrorMessage'
import { SigningStages } from '../models/SigningStages'

function SigningPage() {
  const { postData, instanceData } = useFormData()
  useInstanceValidator(instanceData)

  const navigate = useNavigate()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [signingStage, setSigningStage] = useState(SigningStages.SELECTMETHOD)
  const [errorMessage, setErrorMessage] = useState('')
  const [signingSessionData, setSigningSessionData] = useState({
    authenticationUrl: '',
    idpData: {
      qrData: '',
      autostartToken: ''
    }
  })

  useEffect(() => {
    if (signingStage === SigningStages.SELECTBANK) {
      navigate('/selectbank')
      return
    }

    if (signingStage === SigningStages.COMPLETED) {
      navigate('/welcome')
      return
    }
  }, [signingStage])

  const StartSigningSession = async (provider, signingStage) => {
    setLoading(true)
    const storeKycData = await postKycData(instanceData.instanceId, postData)

    if (!storeKycData) {
      setErrorMessage(t('bankIdSigningPage.postError'))
      setLoading(false)
      return
    }

    const signingSession = await signKyc(instanceData.instanceId, postData, provider)

    if (!signingSession) {
      setErrorMessage(t('bankIdSigningPage.signingSessionError'))
      setLoading(false)
      return
    }

    setLoading(false)

    if (signingSession?.status === 200) {
      const signingSessionData = await signingSession.data
      setSigningSessionData(signingSessionData)
      setSigningStage(() => signingStage)
    }
  }

  const StartMockSigningSession = async (signingStage) => {
    setLoading(true)
    const storeKycData = await postKycData(instanceData.instanceId, postData)

    if (!storeKycData) {
      setErrorMessage(t('bankIdSigningPage.postError'))
      setLoading(false)
      return
    }

    setTimeout(() => {
      setLoading(false)

      setSigningStage(() => signingStage)
    }, 1000)
  }

  const cancelSigning = () => {
    setErrorMessage(t('bankIdSigningPage.signingCanceledByUser'))
    setSigningStage(SigningStages.SELECTMETHOD)
  }

  const signingMethods = [
    {
      'id': 'sbid',
      'label': t('signingMethodLabels.sbid'),
      'icon': BankIdLogo,
      'handler': useCallback(() => StartSigningSession('sbid', SigningStages.SWEDISHQRPOLLING), [])
    },
    {
      'id': 'nbid',
      'label': t('signingMethodLabels.nbid'),
      'icon': BankIdNoLogo,
      'handler': useCallback(() => StartSigningSession('nbid', SigningStages.NORWEGIANPOPUP), [])
    },
    {
      'id': 'fmock',
      'label': t('signingMethodLabels.fmock'),
      'icon': FinlandFlag,
      'handler': useCallback(() => StartMockSigningSession(SigningStages.FINNISHMOCK), [])
    }
  ]

  return (
    <div>
      <h1 className='mb-8 text-center'>{t('bankIdSigningPage.pageTitle')}</h1>

      {signingStage === SigningStages.SELECTMETHOD &&
        <div className='max-w-md mx-auto'>
          <p>{t('bankIdSigningPage.signingDescription')}</p>
          <p className='font-medium'>{t('bankIdSigningPage.authorizedSignatoryNote')}</p>
          <p className='small mt-6'>{t('bankIdSigningPage.signingMethod')}</p>

          {errorMessage !== '' && <ErrorMessage message={errorMessage} />}

          {/* Signing methods selection */}
          <div className='relative'>
            {instanceData.company.country !== 'FI' &&
              <BigButton
                iconSrc={signingMethods[0].icon}
                onClickHandler={signingMethods[0].handler}
                label={signingMethods[0].label}
                key={signingMethods[0].id}
              />
            }

            {instanceData.company.country !== 'FI' &&
              <BigButton
                iconSrc={signingMethods[1].icon}
                onClickHandler={signingMethods[1].handler}
                label={signingMethods[1].label}
                key={signingMethods[1].id}
              />
            }

            {instanceData.company.country === 'FI' &&
              <BigButton
                iconSrc={signingMethods[2].icon}
                onClickHandler={signingMethods[2].handler}
                label={signingMethods[2].label}
                key={signingMethods[2].id}
              />
            }

            {loading &&
              <div className='absolute inset-0 bg-white/50 flex justify-center items-center'>
                <SpinnerCircular size={50} thickness={180} color='rgb(80 158 248)' speed={118} secondaryColor='rgba(255,255,255,0)' />
              </div>
            }
          </div>
        </div>
      }

      {signingStage === SigningStages.SWEDISHQRPOLLING &&
        <SwedishBankId
          initialQr={signingSessionData.idpData?.qrData ?? ""}
          autostartToken={signingSessionData.idpData?.autoStartToken ?? ""}
          cancelSigning={cancelSigning}
          setErrorMessage={setErrorMessage}
          setStageHandler={setSigningStage}
          instanceData={instanceData}
        />
      }

      {signingStage === SigningStages.NORWEGIANPOPUP &&
        <ExternalSigning
          logo={signingMethods.find(x => x.id === 'nbid').icon}
          label={signingMethods.find(x => x.id === 'nbid').label}
          authenticationUrl={signingSessionData.authenticationUrl}
          cancelSigning={cancelSigning}
          setErrorMessage={setErrorMessage}
          setStageHandler={setSigningStage}
          instanceData={instanceData}
        />
      }

      {signingStage === SigningStages.FINNISHMOCK &&
        <MockSigning
          cancelSigning={cancelSigning}
          setErrorMessage={setErrorMessage}
          setStageHandler={setSigningStage}
          instanceData={instanceData}
          postData={postData}
        />
      }

      {signingStage === SigningStages.PROCESSINGDATA &&
        <ProcessingData
          instanceData={instanceData}
          postData={postData}
          setSigningStage={setSigningStage}
          setErrorMessage={setErrorMessage}
        />
      }
    </div>
  )
}

export default transition(SigningPage)
