import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enTranslation from './assets/languages/en.json'
import seTranslation from './assets/languages/se.json'
import noTranslation from './assets/languages/no.json'
import fiTranslation from './assets/languages/fi.json'

i18n
  .use(initReactI18next)
  .init({
      fallbackLng: 'en',
      resources: {
      en: {
          translation: enTranslation,
      },
      se: {
          translation: seTranslation,
      },
      no: {
        translation: noTranslation,
      },
      fi: {
        translation: fiTranslation,
      },
      },
      debug: true,
      interpolation: {
      escapeValue: false,
      },
  })