import { motion } from "framer-motion";

const animations = {
  initial: { opacity: 0, x: 0 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -25 }
}

const transition = (Child) => {
  return (props) => (
    <motion.div 
      variants={animations} 
      initial="initial" 
      animate="animate" 
      exit="exit"
      transition={{ duration: 0.27 }}>
      <Child {...props} />
    </motion.div>
  )
}

export default transition;