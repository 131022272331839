import React, { useState } from 'react';
import BankIdLogo from '../../assets/images/BankID_logo.svg'
import { useTranslation } from 'react-i18next'
import { QRCodeSVG } from 'qrcode.react';
import { isMobile } from 'react-device-detect';
import transition from '../../transition';

function ConsentSignComponent({ scaData }) {
  const { t } = useTranslation()
  const [showQR, setShowQR] = useState(false);

  const bankIdURL = `bankid:///?autostarttoken=${scaData.token}&redirect=null`;

  const handleOpenBankID = () => {
    window.open(bankIdURL, '_self');
  };

  const handleShowQRCode = () => {
    setShowQR(true);
  };

  const handleUseSameDevice = () => {
    setShowQR(false);
    handleOpenBankID();
  };

  return (
    <div className='mx-auto max-w-md text-center'>
      <h2 className='mb-8'>{t('selectBankPage.consentSign.pageTitle')}</h2>
      <h2 className='mb-8 text-op-blue'>{t('selectBankPage.consentSign.pageStep')}</h2>
      <img src={BankIdLogo} width={150} className='mb-8 mx-auto' alt='Bank ID Logo' />

      {isMobile ? (
        <>
          {!showQR ? (
            <div className='flex flex-col'>
              <button className='op-btn mb-4' onClick={handleOpenBankID}>{t('selectBankPage.consentSign.bankIdSameDevice')}</button>
              <button className='op-btn' onClick={handleShowQRCode}>{t('selectBankPage.consentSign.bankIdOtherDevice')}</button>
            </div>
          ) : (
            <>
              {scaData.token && (
                <QRCodeSVG className='mx-auto mb-8' value={bankIdURL} size={200} />
              )}
              <button className='text-op-blue underline font-medium' onClick={handleUseSameDevice}>{t('selectBankPage.consentSign.useBankIdSameDevice')}</button>
            </>
          )}
        </>
      ) : (
        scaData.token ? (
          <QRCodeSVG className='mx-auto' value={bankIdURL} size={200} />
        ) : scaData.image && (
          <img className='mx-auto' src={scaData.image} alt='Bank ID QR Code' style={{ width: '200px', height: '200px' }} />
        )
      )}
    </div>
  );
}

export default transition(ConsentSignComponent);