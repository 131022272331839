export const formSteps = {
  "en": [
    {
      path: ['/'],
      label: 'Terms'
    },
    {
      path: ['/verification'],
      label: 'Verification'
    },
    {
      path: ['/contact'],
      label: 'Contact'
    },
    {
      path: ['/yourbusiness'],
      label: 'Your business'
    },
    {
      path: ['/beneficialowner'],
      label: 'Beneficial owner'
    },
    {
      path: ['/pep'],
      label: 'PEP'
    },
    {
      path: ['/summary'],
      label: 'Summary'
    },
    {
      path: ['/signing', '/signingstarted', '/signingprocessing', '/signing2'],
      label: 'Signing'
    }
  ],
  "se": [
    {
      path: ['/'],
      label: 'Villkor'
    },
    {
      path: ['/verification'],
      label: 'Verifikation'
    },
    {
      path: ['/contact'],
      label: 'Kontakt'
    },
    {
      path: ['/yourbusiness'],
      label: 'Ditt företag'
    },
    {
      path: ['/beneficialowner'],
      label: 'Verklig huvudman'
    },
    {
      path: ['/pep'],
      label: 'PEP'
    },
    {
      path: ['/summary'],
      label: 'Sammanfattning'
    },
    {
      path: ['/signing', '/signingstarted', '/signingprocessing', '/signing2'],
      label: 'Signering'
    }
  ],
  "no": [
    {
      path: ['/'],
      label: 'Vilkår'
    },
    {
      path: ['/verification'],
      label: 'Verifisering'
    },
    {
      path: ['/contact'],
      label: 'Kontakt'
    },
    {
      path: ['/yourbusiness'],
      label: 'Ditt selskap'
    },
    {
      path: ['/beneficialowner'],
      label: 'Reell eier'
    },
    {
      path: ['/pep'],
      label: 'PEP'
    },
    {
      path: ['/summary'],
      label: 'Sammendrag'
    },
    {
      path: ['/signing', '/signingstarted', '/signingprocessing', '/signing2'],
      label: 'Signering'
    }
  ],
  "fi": [
    {
      path: ['/'],
      label: 'Ehdot'
    },
    {
      path: ['/verification'],
      label: 'Varmennus'
    },
    {
      path: ['/contact'],
      label: 'Yhteystiedot'
    },
    {
      path: ['/yourbusiness'],
      label: 'Yrityksesi'
    },
    {
      path: ['/beneficialowner'],
      label: 'Todellinen omistaja'
    },
    {
      path: ['/pep'],
      label: 'PEP'
    },
    {
      path: ['/summary'],
      label: 'Yhteenveto'
    },
    {
      path: ['/signing', '/signingstarted', '/signingprocessing', '/signing2'],
      label: 'Allekirjoitus'
    }
  ]
}