import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { getSigningStatus } from "../../clients/kycClient"
import { SigningStages } from "../../models/SigningStages"
import useIntervalAsync from '../../utils/useIntervalAsync'

function ExternalSigning({ logo, label, authenticationUrl, cancelSigning, setErrorMessage, setStageHandler, instanceData }) {
  const { t } = useTranslation()

  const tryOpenSigningPopup = () => window.open(authenticationUrl, label, 'width=420, height=600')

  const getStatus = useCallback(async () => {
    const response = await getSigningStatus(instanceData.instanceId)

    if (response === undefined || response === 500) {
      setErrorMessage(t('externalSigning.generalError'))
      setStageHandler(SigningStages.SELECTMETHOD)
      return
    }

    if (response?.data.status === 'SUCCESS') {
      setStageHandler(SigningStages.PROCESSINGDATA)
      return
    }

    if (response?.data.status === 'ABORT') {
      cancelSigning()
      return
    }
  }, [instanceData.instanceId, setStageHandler])

  useIntervalAsync(getStatus, 1000)

  return (
    <div className="text-center">

      <div className="block max-w-sm mx-auto">
        <button onClick={tryOpenSigningPopup} className="border border-gray-4 shadow-lg p-8 rounded-lg w-full flex flex-col items-center hover:shadow-xl hover:border-op-blue text-op-blue font-medium transition-all">
          <img src={logo} alt={label} className="max-w-sm mb-6 mx-auto" />
          { t('externalSigning.openSigning') }
        </button>
      </div>

      <hr className="my-8" />
      <button className="cursor-pointer font-medium text-op-blue underline" onClick={cancelSigning}>{t('bankIdSigningPage.cancelSigning')}</button>
    </div>
  )
}

export default ExternalSigning