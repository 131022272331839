import { SpinnerDotted } from "spinners-react"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"
import { SigningStages } from "../../models/SigningStages"
import { processKycData } from "../../clients/kycClient"
import { getAccountSelectionEnabled } from "../../clients/bankingClient"
import { useNavigate } from "react-router-dom"
import { useFormData } from "../../FormDataContext"

function ProcessingData({ instanceData, setSigningStage, setErrorMessage }) {
  const { t } = useTranslation()
  const { updateInstanceData } = useFormData()
  const navigate = useNavigate()

  const processData = async () => {
    const processDataResponse = await processKycData(instanceData.instanceId)
    if (processDataResponse !== 200) {
      setSigningStage(SigningStages.SELECTMETHOD)
      setErrorMessage(t('bankIdSigningPage.error'))
      return
    }

    const kycPlusResponse = await getAccountSelectionEnabled(instanceData.instanceId)

    const { validKyc, kycPlus } = kycPlusResponse.data
    updateInstanceData({kycPlus : kycPlus})

    if (validKyc) {
      if (kycPlus) {
        setSigningStage(SigningStages.SELECTBANK)
        return
      } else {
        setSigningStage(SigningStages.COMPLETED)
        return
      }
    } else {
      navigate('/error');
      return
    }
  }

  useEffect(() => {
    processData()
  }, [])

  return (
    <div className='text-center'>
      <SpinnerDotted size={71} thickness={135} speed={100} color='#509EF8' className='mx-auto my-8' />
      <p>{t('bankIdSigningPage.processingDataText')}</p>
    </div>
  )
}

export default ProcessingData