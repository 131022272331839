import React from 'react'
import { Link } from 'react-router-dom'
import transition from '../transition'
import { useFormData } from '../FormDataContext'
import { formatName, formatPsuId, isCeo, isChairman, containsBeneficialBoardMember } from '../mapHelper'
import { useInstanceValidator } from '../InstanceValidator'
import Input from '../Components/Form/Input'
import { useTranslation } from 'react-i18next'
import { reasonableName } from '../validation'
import { useNavigate } from 'react-router-dom'

function BeneficialOwnerPage() {
  const { instanceData, postData } = useFormData()
  useInstanceValidator(instanceData)
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div>
      <h1 className='mb-8 text-center'>{t('companyInfoPage.pageTitle')}</h1>

      <div className='mx-auto max-w-md'>
        <div className='mb-4'>
          { instanceData.company.beneficialOwners.length > 0 ? (
            <>
              <p>{t('companyInfoPage.beneficialOwnersFound')}</p>

              <ul className='ml-4 list-disc mb-8'>
                { instanceData.company.beneficialOwners.map(x => 
                  <li className='font-medium' key={x.psuId}>{formatName(x.name)}, {formatPsuId(x.psuId)}</li>
                )}
              </ul>

              <Link to='/pep' className='op-btn w-full mb-4'>
                {t('companyInfoPage.button')}
              </Link>

              <div className='mb-8'>
                <h4 className='mb-2'>{t('companyInfoPage.verifyDataNotCorrect')}</h4>
                <p className='mb-8'>{t('companyInfoPage.verifyDataExplanation')}</p>
                <h4 className='mb-2'>{t('companyInfoPage.dataUpdateQuestion')}</h4>
                <p>{t('companyInfoPage.dataUpdateExplanation')}</p>
              </div>
            </>
          ) : containsBeneficialBoardMember(instanceData.company.boardMembers) ? (
            <>
              <p className='mb-4'>{t('companyInfoPage.alternativeBeneficialOwners')}</p>

              <ul className='ml-4 list-disc mb-8'>
                { instanceData.company.boardMembers.map(x => {
                  if (isCeo(x.role)) {
                    return (
                      <li className='font-medium' key={x.psuId}>{t('companyInfoPage.ceoLabel')}: {formatName(x.name)}, {formatPsuId(x.psuId)}</li>
                    )
                  }
                  if (isChairman(x.role)) {
                    return (
                      <li className='font-medium' key={x.psuId}>{t('companyInfoPage.chairmanLabel')}: {formatName(x.name)}, {formatPsuId(x.psuId)}</li>
                    )
                  }
                  return null
                })}
              </ul>

              <Link to='/pep' className='op-btn w-full'>
                {t('companyInfoPage.button')}
              </Link>
            </>
          ) : (
            <form onSubmit={e => e.preventDefault()}>
              <p>{t('companyInfoPage.enterAuthorizedSignatory')}</p>

              <Input label={t('companyInfoPage.nameLabel')} value={postData.alternative_beneficial_owner_name} id='alternative_beneficial_owner_name' />
              <Input label={t('companyInfoPage.personalIdLabel')} value={postData.alternative_beneficial_owner_ssn} id='alternative_beneficial_owner_ssn' />
              <button onClick={() => navigate('/pep')} className='op-btn w-full' disabled={!reasonableName(postData.alternative_beneficial_owner_name) || postData.alternative_beneficial_owner_ssn.length < 10}>
                {t('companyInfoPage.button')}
              </button>
            </form>
          )}
        </div>

        <p className='smaller'>{t('companyInfoPage.beneficialOwnerDefinition')}</p>
        <p className='smaller'>{t('companyInfoPage.relatedPartiesDefinition')}</p>
        <p className='smaller'>{t('companyInfoPage.noBeneficialOwnerInfo')}</p>
      </div>
    </div>
  )
}

export default transition(BeneficialOwnerPage)