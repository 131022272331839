export const SigningStages = {
  SELECTMETHOD: 10,
  SWEDISHQRPOLLING: 20,
  NORWEGIANPOPUP: 30,
  FINNISHMOCK: 40,
  PROCESSINGDATA: 50,
  SELECTBANK: 60,
  COMPLETED: 70,
  CANCELED: 80
}
