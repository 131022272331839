import { useTranslation } from "react-i18next"
import { mockSignKyc } from "../../clients/kycClient"
import { SigningStages } from "../../models/SigningStages"

function MockSigning({ cancelSigning, setErrorMessage, setStageHandler, instanceData, postData }) {
  const { t } = useTranslation()

  const signKyc = async () => {
    const mockSigningSession = await mockSignKyc(instanceData.instanceId, postData, 'fmock')

    if (!mockSigningSession) {
      setErrorMessage(t('bankIdSigningPage.signingSessionError'))
      setStageHandler(SigningStages.SELECTMETHOD)
      return
    }

    setStageHandler(SigningStages.PROCESSINGDATA)
  }

  return (
    <div className="text-center">

      <div className="block max-w-sm mx-auto">
        <button onClick={signKyc} className="border border-gray-4 shadow-lg p-8 rounded-lg w-full flex flex-col items-center hover:shadow-xl hover:border-op-blue text-op-blue font-medium transition-all">
        { t('mockSigning.sign') }
        </button>
      </div>

      <hr className="my-8" />
      <button className="cursor-pointer font-medium text-op-blue underline" onClick={cancelSigning}>{t('bankIdSigningPage.cancelSigning')}</button>
    </div>
  )
}

export default MockSigning